import React, { useState, useEffect } from "react";
import { Button, Input, Label, Modal, ModalBody, ModalFooter } from "reactstrap";
import Cropper from "react-easy-crop";
import getCroppedImg from "../CroppedImg";
import Swal from "sweetalert2";
import heic2any from "heic2any";
import defaultImage from "../../../assets/images/uploadImage.png"; // Default image path

const UploadImage = ({ 
  label = "Upload Image", 
  name, 
  disabled = false, 
  handleCroppedImage,  // Callback to handle cropped image
  filePath // Image path from the backend
}) => {
  const [fileSelected, setFileSelected] = useState(false);
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);
  const [image, setImage] = useState(filePath); // Use backend image or default image
  
  useEffect(() => {
    if (filePath) {
      setImage(filePath); // Update the image when the filePath prop changes
    }
  }, [filePath]);

  const handleFileChange = async (event) => {
    const file = event?.target?.files[0];
    const sizeLimit = 10 * 1024 * 1024; // 10 MB limit

    if (file) {
      if (file.size > sizeLimit) {
        Swal.fire("Please add an image below 10 MB.", "", "warning");
        return;
      }

      const isHeicFile = file.type === "image/heic" || file.name.toLowerCase().endsWith(".heic");

      if (isHeicFile) {
        try {
          const convertedBlob = await heic2any({ blob: file, toType: "image/jpeg" });
          const reader = new FileReader();
          reader.onloadend = () => {
            setImageSrc(reader.result);
            setCropModalOpen(true);
          };
          reader.readAsDataURL(convertedBlob);
        } catch (error) {
          Swal.fire("Failed to process HEIC/HEIF image.", "", "error");
        }
      } else {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImageSrc(reader.result);
          setCropModalOpen(true);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const saveCroppedImage = (croppedFile) => {
    if (croppedFile && handleCroppedImage) {
      handleCroppedImage(croppedFile); // Call the callback function if defined
    }
  };

  const handleCropConfirm = async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedArea, {
        width: 1320,
        height: 150,
      });
      setImage(croppedImage);  // Set cropped image directly
      setFileSelected(true);
      setCropModalOpen(false);
      saveCroppedImage(croppedImage);  // Call the save function
    } catch (error) {
      console.error("Failed to crop image", error);
    }
  };

  return (
    <>
      <Label for="upload">{label}</Label>
      <div className="d-flex flex-column align-items-center">
        <div onClick={() => document.getElementById("upload").click()} style={{ cursor: "pointer" }}>
          <img
            src={image ? image : defaultImage}
            alt="Preview"
            style={{ width: "100%", height: "100px", objectFit: "contain" }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = defaultImage; // Fallback to default image if there's an error
            }}
            className="mb-2"
          />
        </div>

        <Label for="upload" className="border w-80 text-center rounded p-2 custom-file-upload">
          {fileSelected ? "Upload a new file" : "Choose a file"}
        </Label>
        <Input
          type="file"
          id="upload"
          accept="image/*"
          onChange={handleFileChange}
          disabled={disabled}
          style={{ display: "none" }}
        />

        <Modal isOpen={cropModalOpen} toggle={() => setCropModalOpen(!cropModalOpen)}>
          <ModalBody>
            <div style={{ position: "relative", width: "100%", height: 300 }}>
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={1320 / 150}  // Set aspect ratio to 1320:150
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={handleCropComplete}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleCropConfirm}>
              Crop
            </Button>
            <Button color="secondary" onClick={() => setCropModalOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

export default UploadImage;
