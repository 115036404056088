import React from "react";
import { Line } from "react-chartjs-2";
import PuffLoader from "react-spinners/PuffLoader";

export const CompanyGraph  = ({ filterData }) => {
  if (!filterData) {
    // return <div>Loading...</div>; // Display loading text when filterData is null or undefined
    return (
      <div className="d-flex justify-content-center align-center h-100">
        <PuffLoader size={50} color={"#39ff00"} />
      </div>
    );
  }

  const Overview = {
    labels: filterData.labels,
    dataUnit: "BTC",
    datasets: [
      {
        label: "Sales Overview",
        color: "#798bff",
        fill: true,
        backgroundColor: "rgba(10,120,89,0.2)",
        borderColor: "#798bff",
        barPercentage: 0.5,
        categoryPercentage: 0.5,
        borderWidth: 2,
        lineTension: 0.1,
        pointBorderColor: "transparent",
        pointBackgroundColor: "transparent",
        pointHoverBorderColor: "#798bff",
        pointHoverBackgroundColor: "#fff",
        data: filterData.datasets?.[0]?.data || [],
      },
    ],
  };

  return (
    <Line
      className="sales-overview-chart"
      data={Overview}
      options={{
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            displayColors: false,
            backgroundColor: "#eff6ff",
            titleFont: {
              size: "13px",
            },
            titleColor: "#6783b8",
            titleMarginBottom: 6,
            bodyColor: "#9eaecf",
            bodyFont: {
              size: "12px",
            },
            bodySpacing: 4,
            padding: 10,
            footerMarginTop: 0,
          },
        },
        maintainAspectRatio: false,
        scales: {
          y: {
            display: true,
            ticks: {
              beginAtZero: true,
              color: "#9eaecf",
              font: {
                size: "11px",
              },
              callback: function (value, index, values) {
                return value;
              },
              padding: 10,
              min: 100,
              stepSize: 2,
            },
            grid: {
              tickMarkLength: 0,
            },
          },
          x: {
            display: true,
            ticks: {
              color: "#9eaecf",
              font: {
                size: "9px",
              },
              source: "auto",
              padding: 10,
            },
            grid: {
              color: "transparent",
              tickMarkLength: 0,
              zeroLineColor: "transparent",
            },
          },
        },
      }}
    />
  );
};
