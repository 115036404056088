import React, { useEffect, useState } from "react";
import { Badge, CardTitle, Table } from "reactstrap";
import { Link } from "react-router-dom";
import { formatDate, getCookie } from "../../utils/Utils";

let token = process.env.REACT_APP_AUTH_TOKEN;

const PopularCompanies = () => {
  return (
    <React.Fragment>
      {/* New Table for Top 3 Companies */}
      <div className="card-title-group align-start mb-3 fs-4 fw-medium align-center">
        <CardTitle>
            Top 3 Companies with Highest Revenue
          {/* <Badge className=" badge-dot badge-lg" color="gray">
          </Badge> */}
        </CardTitle>
      </div>
      <Table className="table-bordered">
        <thead>
          <tr>
            <th>Company Name</th>
            <th>Total Revenue</th>
            <th>Completed Payments</th>
            <th>Pending Payments</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td> Dj Prathamesh</td>
            <td>$10,000</td>
            <td>$7,500</td>
            <td>$2,500</td>
          </tr>
          <tr>
            <td>djSourabh</td>
            <td>$8,000</td>
            <td>$6,000</td>
            <td>$2,000</td>
          </tr>
          <tr>
            <td> InovationT Dj Services</td>
            <td>$6,000</td>
            <td>$4,500</td>
            <td>$1,500</td>
          </tr>
        </tbody>
      </Table>
    </React.Fragment>
  );
};
export default PopularCompanies;
