import React, { useEffect, useState } from "react";
import { Block, BlockHead, BlockHeadContent, BlockTitle, PreviewCard } from "../../../components/Component";
import Content from "../../../layout/content/Content";
import { getCookie } from "../../../utils/Utils";
import CustomizeReactDataTable from "./CustomizeReactDataTable";

export const NotAvailable = () => <div style={{ textAlign: "center" }}>----</div>;

const Planpayment = () => {
  const [companyData, setCompanyData] = useState(null);
  const [pageNo, setPageNo] = useState(10);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState("");

  console.log(companyData);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/admin/get-companies`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken"),
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      setCompanyData(responseData?.data?.list);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const dataTableColumns = [
    {
      name: "Company Id",
      selector: (row) => row?.id || <NotAvailable />,
      sortable: true,
    },
    {
      name: "Company Name",
      selector: (row) => row?.company_name || <NotAvailable />,
      sortable: true,
    },
    {
      name: "Plan Name",
      selector: (row) => row?.plan_name || <NotAvailable />,
      sortable: true,
    },
    {
      name: "Payment Method",
      selector: (row) =>
        row?.payment_mode === "3" ? (
          "Paypal"
        ) : row?.payment_mode === "1" ? (
          "Card Payment"
        ) : row?.payment_mode === "4" ? (
          "Wire Transfer"
        ) : (
          <NotAvailable />
        ),
      sortable: true,
    },
    // {
    //   name: "Issue Date",
    //   selector: (row) => row?.issuedate || <NotAvailable />,
    //   sortable: true,
    // },
    {
      name: "End Date",
      selector: (row) => row?.end_date || <NotAvailable />,
      sortable: true,
    },
  ];

  let checkPlanNameCountNull = 0;
  let checkPlanNameCount = 0;
  for (let i = 0; i < companyData?.length; i++) {
    if (companyData?.[i]?.plan_name === null) {
      checkPlanNameCountNull++;
    } else if (companyData?.[i]?.plan_name !== null) {
      checkPlanNameCount++;
    }
  }

  return (
    <>
      <Content>
        <Block size="lg">
          <BlockHead>
            <BlockHeadContent>
              <div className="d-flex flex-row align-center justify-between">
                <BlockTitle tag="h4">Plans Payment Details</BlockTitle>
                <div>
                  <div className="mx-1">
                    <span> Active Plans :</span>
                    <span className="text-primary fw-semibold px-1">{checkPlanNameCount}</span>
                  </div>
                  <div className="mx-1">
                    <span>Inactive Plans :</span>
                    <span className="text-primary fw-semibold px-1">{checkPlanNameCountNull}</span>
                  </div>
                </div>
              </div>
            </BlockHeadContent>
          </BlockHead>
          <PreviewCard>
            <CustomizeReactDataTable
              data={companyData}
              columns={dataTableColumns}
              searchBy={"company_name"}
              pagination
              actions
              loading={loading}
              setSearch={setSearch}
              // searchBy={companyData?.company_name}
            />
          </PreviewCard>
        </Block>
      </Content>
    </>
  );
};

export default Planpayment;
