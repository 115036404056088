import React, { useState, useEffect } from "react";
import "./CategoryManager.css";
import ShowRecords from "../show-records/ShowRecords";
import { BlockTitle, Button } from "../../../components/Component";
import { toast } from "react-toastify";
import { getCookie } from "../../../utils/Utils";
import { Col, Row } from "reactstrap";
import { Link } from 'react-router-dom';


import Swal from "sweetalert2";


export default function CategoryManager() {
  const [formData, setFormData] = useState({ name: "", type: "" });
  const [data, setData] = useState([]);
  const [type, setType] = useState("category");
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState(null);
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const resetForm = () => {
    setFormData({ name: "", type: "" });
    setEditMode(false);
    setEditId(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, type } = formData;
    console.log("Submitting Data: ", formData);

    try {
        setLoading(true);
        const formDataToSubmit = new FormData();
        formDataToSubmit.append("name", name);
        formDataToSubmit.append("type", type);

        const url = `${process.env.REACT_APP_API_URL}api/v1/admin/add-update-services-or-category`;
        const method = "POST";
        const headers = { authToken: getCookie("authToken") };

        if (editMode) {
            formDataToSubmit.append("id", editId);
        }

        const response = await fetch(url, { method, body: formDataToSubmit, headers });
        const result = await response.json();

        if (result?.status) {
            toast.success(editMode ? "Updated successfully" : `${type === "services" ? "Service" : "Category"} added successfully`);
            await fetchData(); // Refresh data after submit
        } else {
            toast.error(result?.errors?.[0] || "Error occurred");
        }
        resetForm();
    } catch (error) {
        console.error(error);
        toast.error("An error occurred while processing your request.");
    } finally {
        setLoading(false);
    }
};

  const fetchData = async () => {
    const formData = new FormData();
    formData.append("type", type);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/admin/get-service-or-category`, {
        method: "POST",
        headers: { authToken: getCookie("authToken") },
        body: formData,
      });
      const result = await response.json();
      console.log("Fetched Data: ", result);

      if (result?.status) {
        setData(result.data);
      } else {
        // toast.error("Failed to fetch data from API");
        toast.error("Failed to fetch data.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("An error occurred while fetching data.");
    }
  };

  useEffect(() => {
    fetchData();
  }, [type]);


  const handleEdit = (id, type) => {
    const selectedData = type === "category" ? data.Categories : data.Services;
    const itemToEdit = selectedData?.find((item) => item.id === id);

    setFormData({ name: itemToEdit?.title, type });
    setEditMode(true);
    setEditId(id);
  };

  const handleDelete = async (id, type) => {
    const result = await Swal.fire({
        title: "Are you sure to delete?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/admin/delete-service-or-category`, {
                method: "POST",
                headers: {
                    authToken: getCookie("authToken"),
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ id, type }),
            });

            const result = await response.json();
            if (result?.status) {
                toast.success(`${type === "services" ? "Service" : "Category"} deleted successfully.`);
                await fetchData(); // Refresh data after delete
            } else {
                toast.error(result?.errors?.[0] || "Failed to delete.");
            }
        } catch (error) {
            console.error("Error deleting data:", error);
            toast.error("An error occurred while deleting.");
        }
    } else {
        toast.info("Deletion cancelled.");
    }
};

  return (
    <>
      <div className="d-flex flex-row align-center justify-between" style={{ paddingLeft: "30px", paddingTop: "40px" }}>
        <BlockTitle tag="h4">Add Category and Services</BlockTitle>
        {/* <Button color="secondary" onClick={() => window.history.back()} style={{marginRight:"30px"}}>
          Back
        </Button> */}
         <Link to="/site-setting">
          <Button className="toggle" color="primary" disabled={loading} style={{marginRight:"33px"}}>
            <span>Back</span>
          </Button>
        </Link>
      </div>

      <div className="d-flex flex-column flex-lg-row p-4">
        <div className="flex-fill-form lg:pl-5 mb-4 lg:mb-0">
          <form onSubmit={handleSubmit} className="p-4 w-100 lg:w-85 space-y-4">
            <div className="mb-4">
              <div className="form-label-cs text-lg font-weight-semibold pb-2">Select Type:</div>
              <div className="d-flex flex-column flex-lg-row align-items-start lg:align-items-center">
                <div className="form-check mr-4">
                  <input
                    type="radio"
                    name="type"
                    id="category"
                    value="category"
                    checked={formData.type === "category"}
                    onChange={handleInputChange}
                    required
                    className="form-check-input"
                    disabled={loading}
                  />
                  <label htmlFor="category" className="form-check-label">Category</label>
                </div>
                <div className="form-check mr-4">
                  <input
                    type="radio"
                    id="services"
                    name="type"
                    value="services"
                    checked={formData.type === "services"}
                    onChange={handleInputChange}
                    className="form-check-input"
                    disabled={loading}
                  />
                  <label htmlFor="services" className="form-check-label">Services</label>
                </div>
              </div>
            </div>

            <Row className="align-items-center g-2 mb-4">
              <Col xs="12" sm="7">
                <div className="form-label-cs pb-2">
                  <label htmlFor="name">Name:</label>
                </div>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="Enter category and service name"
                  required
                  className="form-control form-control-cs rounded p-2"
                  disabled={loading}
                />
              </Col>
              <Col xs="12" sm="5" className="d-flex align-items-center text-start mt-md-5" >
                <Button className="toggle" color="primary" disabled={loading}>
                  <span>{loading ? "Processing..." : editMode ? "Update" : "Add"}</span>
                </Button>
              </Col>
            </Row>
          </form>
        </div>

        <div className="flex-fill overflow-auto">
          <ShowRecords
            data={data}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            loading={loading}
            setType={setType}
            type={type}
            pagination
          />
        </div>
      </div>
    </>
  );
}
