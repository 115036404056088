import React from 'react'; 
import { Container, Row, Col, Card } from 'reactstrap';
import { Link, Outlet } from 'react-router-dom';
import settingLogo from '../../../images/settings_logo/Site-setting.png';
import cate_ser_logo from '../../../images/settings_logo/category-services.png';
import payment_gateway from '../../../images/settings_logo/payment-gateway.png';

export default function Settings() {
  const imageStyle = {
    width: '150px',
    height: '150px',
    objectFit: 'cover', // Keeps the aspect ratio
    margin: '0 auto',   // Centers the image horizontally
    display: 'block',    // Allows margin auto to center the image
  };

  return (
    <Container className="my-5">
      <Row className="g-5">
        {/* Column 1 - Logo Change */}
        <Col xs="12" md="6" lg="4">
          <Card className="p-5 text-center border" style={{ cursor: 'pointer' }}>
            <Link to="logoChange"> 
              <img src={settingLogo} alt="setting" style={imageStyle} />
              <h6 className="mt-4">Settings</h6>
            </Link>
          </Card>
        </Col>

        {/* Column 2 - Category and Services Manager */}
        <Col xs="12" md="6" lg="4">
          <Card className="p-5 text-center border">
            <Link to="categorymanager">
              <img src={cate_ser_logo} alt="setting" style={imageStyle} />
              <h6 className="mt-4">Category and Services Manager</h6>
            </Link>
          </Card>
        </Col>

        {/* Column 3 - Payment Gateway Settings */}
        <Col xs="12" md="12" lg="4">
          <Card className="p-5 text-center border">
            <Link to="paymentgateway">
              <img src={payment_gateway} alt="setting" style={imageStyle} />
              <h6 className="mt-4">Payment Gateway Settings</h6>
            </Link>
          </Card>
        </Col>
      </Row>

      {/* Outlet for rendering nested routes */}
      <Outlet />
    </Container>
  );
}
