import React, { useContext, useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { Card, Modal, ModalBody, Badge, TabPane, TabContent, Nav, NavItem } from "reactstrap";
import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Col,
  Row,
  OverlineTitle,
  Sidebar,
  UserAvatar,
  PreviewCard,
} from "../../../components/Component";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { findUpper } from "../../../utils/Utils";
import User from "../../../images/Party DJ Mix.png";
import User2 from "../../../images/avatar/b-sm.jpg";
import User3 from "../../../images/avatar/a-sm.jpg";
import User4 from "../../../images/avatar/d-sm.jpg";
import classnames from "classnames";

const ManageProfiles = () => {
  const userData = [
    {
      id: 1,
      avatarBg: "purple",
      name: "Johnathan Clarke",
      displayName: "Johnathan",
      dob: "10 Aug, 1980",
      role: "Customer",
      checked: false,
      email: "info@softnio.com",
      balance: "35040.34",
      phone: "818474958",
      emailStatus: "success",
      kycStatus: "success",
      lastLogin: "10 Feb 2020",
      status: "Active",
      address: "2337 Kildeer Drive",
      state: "Kentucky",
      country: "Canada",
      designation: "UI/UX Designer",
      projects: "213",
      performed: "87.5",
      tasks: "587",
    },
    {
      id: 2,
      avatarBg: "purple",
      image: User3,
      name: "Ashley Lawson",
      dob: "10 Sept, 1990",
      role: "Investor",
      email: "ashley@softnio.com",
      balance: "580.00",
      checked: false,
      phone: "1243941787",
      emailStatus: "success",
      kycStatus: "pending",
      lastLogin: "07 Feb 2020",
      status: "Pending",
      country: "United States",
      designation: "UI/UX Designer",
      projects: "213",
      performed: "87.5",
      tasks: "587",
    },
    {
      id: 3,
      avatarBg: "info",
      name: "Joe Larson",
      dob: "19 Jan, 1985",
      role: "Customer",
      email: "larson@example.com",
      balance: "32000.34",
      checked: false,
      phone: "1686032320",
      emailStatus: "success",
      kycStatus: "success",
      lastLogin: "04 Feb 2020",
      status: "Active",
      country: "England",
      designation: "UI/UX Designer",
      projects: "213",
      performed: "87.5",
      tasks: "587",
    },
    {
      id: 4,
      avatarBg: "danger",
      name: "Jane Montgomery",
      dob: "24 April, 1985",
      role: "Subscriber",
      email: "jane84@example.com",
      balance: "0.00",
      checked: false,
      phone: "4392715360",
      emailStatus: "alert",
      kycStatus: "alert",
      lastLogin: "01 Feb 2020",
      status: "Suspend",
      country: "United States",
      designation: "UI/UX Designer",
      projects: "213",
      performed: "87.5",
      tasks: "587",
    },
    {
      id: 5,
      avatarBg: "purple",
      name: "Frances Burns",
      dob: "30 May, 2000",
      role: "Manager",
      image: User,
      email: "frances@example.com",
      balance: "42.50",
      checked: false,
      phone: "6391303150",
      emailStatus: "pending",
      kycStatus: "error",
      lastLogin: "31 Jan 2020",
      status: "Active",
      country: "Bangladesh",
      designation: "UI/UX Designer",
      projects: "213",
      performed: "87.5",
      tasks: "587",
    },
    {
      id: 6,
      avatarBg: "primary",
      name: "Alan Butler",
      dob: "10 Feb, 1997",
      role: "Investor",
      image: User2,
      email: "butler@example.com",
      balance: "440.34",
      checked: false,
      phone: "9633091706",
      emailStatus: "pending",
      kycStatus: "warning",
      lastLogin: "18 Jan 2020",
      status: "Inactive",
      country: "India",
      designation: "UI/UX Designer",
      projects: "213",
      performed: "87.5",
      tasks: "587",
    },
    {
      id: 7,
      avatarBg: "warning",
      name: "Victoria Lynch",
      dob: "02 May, 1993",
      role: "Investor",
      email: "victoria@example.com",
      balance: "59400.68",
      checked: false,
      phone: "8119854846",
      emailStatus: "success",
      kycStatus: "success",
      lastLogin: "15 Jan 2020",
      status: "Active",
      country: "China",
      designation: "UI/UX Designer",
      projects: "213",
      performed: "87.5",
      tasks: "587",
    },
    {
      id: 8,
      avatarBg: "success",
      name: "Patrick Newman",
      dob: "15 Feb, 1997",
      role: "Customer",
      email: "patrick@example.com",
      balance: "30.00",
      checked: false,
      phone: "9422384474",
      emailStatus: "success",
      kycStatus: "pending",
      lastLogin: "08 Jan 2020",
      status: "Active",
      country: "India",
      designation: "UI/UX Designer",
      projects: "213",
      performed: "87.5",
      tasks: "587",
    },
    {
      id: 9,
      avatarBg: "purple",
      name: "Jane Harris",
      dob: "28 Feb, 1985",
      role: "Customer",
      image: User4,
      email: "harris@example.com",
      balance: "5530.23",
      checked: false,
      phone: "1234472384",
      emailStatus: "pending",
      kycStatus: "pending",
      lastLogin: "02 Jan 2020",
      status: "Pending",
      country: "Vietnam",
      designation: "UI/UX Designer",
      projects: "213",
      performed: "87.5",
      tasks: "587",
    },
    {
      id: 10,
      avatarBg: "purple",
      name: "Emma Walker",
      dob: "30 Dec, 1998",
      role: "Investor",
      email: "walker@example.com",
      balance: "55.00",
      checked: false,
      phone: "4634717173",
      emailStatus: "success",
      kycStatus: "success",
      lastLogin: "25 Dec 2019",
      status: "Active",
      country: "United States",
      designation: "UI/UX Designer",
      projects: "213",
      performed: "87.5",
      tasks: "587",
    },
  ];
  const [data, setData] = useState(userData);
  const [sideBar, setSidebar] = useState(false);
  const [user, setUser] = useState();
  const [addNoteModal, setAddNoteModal] = useState(false);
  const navigate = useNavigate();

  let { userId } = useParams();
  const [activeIconTab, setActiveIconTab] = useState("1");
  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };

  // grabs the id of the url and loads the corresponding data
  useEffect(() => {
    const id = userId;
    if (id !== undefined || null || "") {
      let spUser = data.find((item) => item.id === Number(id));
      setUser(spUser);
    } else {
      setUser(data[0]);
    }
  }, [userId, data]);

  // function to toggle sidebar
  const toggle = () => {
    setSidebar(!sideBar);
  };

  return (
    <React.Fragment>
      <Head title="User Details - Regular"></Head>
      {user && (
        <Content>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h3" page>
                  Users / <strong className="text-primary small">{user.name}</strong>
                </BlockTitle>
                <BlockDes className="text-soft">
                  <ul className="list-inline">
                    <li>
                      User ID: <span className="text-base">UD003054</span>
                    </li>
                  </ul>
                </BlockDes>
              </BlockHeadContent>
              <BlockHeadContent>
                <Button color="light" outline className="bg-white d-none d-sm-inline-flex" onClick={() => navigate(-1)}>
                  <Icon name="arrow-left"></Icon>
                  <span>Back</span>
                </Button>
                <a
                  href="#back"
                  onClick={(ev) => {
                    ev.preventDefault();
                    navigate(-1);
                  }}
                  className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                >
                  <Icon name="arrow-left"></Icon>
                </a>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>

          {/* <Block>
            <Card className="card-bordered">
              <div className="card-aside-wrap" id="user-detail-block">
                <div className="card-content">
                  <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        href="#personal"
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                      >
                        <Icon name="user-circle"></Icon>
                        <span>Personal Information</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link disabled"
                        href="#transactions"
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                      >
                        <Icon name="calendar-check"></Icon>
                        <span>Events</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link disabled"
                        href="#documents"
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                      >
                        <Icon name="coin-alt"></Icon>
                        <span>Payments</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link disabled"
                        href="#notifications"
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                      >
                        <Icon name="grid-fill"></Icon>
                        <span>Other</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link disabled"
                        href="#activities"
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                      >
                        <Icon name="activity"></Icon>
                        <span>Activities</span>
                      </a>
                    </li>
                    <li className="nav-item nav-item-trigger d-xxl-none">
                      <Button className={`toggle btn-icon btn-trigger ${sideBar && "active"}`} onClick={toggle}>
                        <Icon name="user-list-fill"></Icon>
                      </Button>
                    </li>
                  </ul>
                  <div className="card-inner">
                    <Block>
                      <BlockHead>
                        <BlockTitle tag="h5">Personal Information</BlockTitle>
                      </BlockHead>
                      <div className="profile-ud-list">
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label"> User Name</span>
                            <span className="profile-ud-value">John</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Company Name</span>
                            <span className="profile-ud-value">{user.name}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Email Address</span>
                            <span className="profile-ud-value">{user.email}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Mobile Number</span>
                            <span className="profile-ud-value">{user.phone}</span>
                          </div>
                        </div>
                      </div>
                    </Block>
                    <Block>
                      <BlockHead className="nk-block-head-line">
                        <BlockTitle tag="h6" className="overline-title text-base">
                          Additional Information
                        </BlockTitle>
                      </BlockHead>
                      <div className="profile-ud-list">
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Register Date</span>
                            <span className="profile-ud-value">08-16-2018 09:04PM</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Address</span>
                            <span className="profile-ud-value">Califorinia, New-York</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">website</span>
                            <span className="profile-ud-value">
                              <a href="https://ipartydjcrm.com/">ipartydjcrm.com</a>
                            </span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Nationality</span>
                            <span className="profile-ud-value">{user.country}</span>
                          </div>
                        </div>
                      </div>
                    </Block>

                    <div className="nk-divider divider md"></div>
                  </div>
                </div>

                <Sidebar toggleState={sideBar}>
                  <div className="card-inner">
                    <div className="user-card user-card-s2 mt-5 mt-xxl-0">
                      <UserAvatar className="lg" theme="primary" text={findUpper(user.name)} />
                      <div className="user-info">
                        <Badge className="ucap" pill color="outline-light">
                          {user.role}
                        </Badge>
                        <h5>{user.name}</h5>
                        <span className="sub-text">{user.email}</span>
                      </div>
                    </div>
                  </div>
                  <div className="card-inner card-inner-sm">
                    <ul className="btn-toolbar justify-center gx-1">
                      <li>
                        <Button
                          href="#tool"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn-trigger btn-icon"
                        >
                          <Icon name="shield-off"></Icon>
                        </Button>
                      </li>
                      <li>
                        <Button
                          href="#mail"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn-trigger btn-icon"
                        >
                          <Icon name="mail"></Icon>
                        </Button>
                      </li>
                      <li>
                        <Button
                          href="#download"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn-trigger btn-icon"
                        >
                          <Icon name="download-cloud"></Icon>
                        </Button>
                      </li>
                      <li>
                        <Button
                          href="#bookmark"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn-trigger btn-icon"
                        >
                          <Icon name="bookmark"></Icon>
                        </Button>
                      </li>
                      <li>
                        <Button
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="btn-trigger btn-icon text-danger"
                        >
                          <Icon name="na"></Icon>
                        </Button>
                      </li>
                    </ul>
                  </div>
                  <div className="card-inner">
                    <div className="overline-title-alt mb-2">In Account</div>
                    <div className="profile-balance">
                      <div className="profile-balance-group gx-4">
                        <div className="profile-balance-sub">
                          <div className="profile-balance-amount">
                            <div className="number">
                              2,500.00 <small className="currency currency-usd">USD</small>
                            </div>
                          </div>
                          <div className="profile-balance-subtitle">Invested Amount</div>
                        </div>
                        <div className="profile-balance-sub">
                          <span className="profile-balance-plus text-soft">
                            <Icon className="ni-plus"></Icon>
                          </span>
                          <div className="profile-balance-amount">
                            <div className="number">1,643.76</div>
                          </div>
                          <div className="profile-balance-subtitle">Profit Earned</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-inner">
                    <Row className="text-center">
                      <Col size="4">
                        <div className="profile-stats">
                          <span className="amount">{user.tasks}</span>
                          <span className="sub-text">Total Order</span>
                        </div>
                      </Col>
                      <Col size="4">
                        <div className="profile-stats">
                          <span className="amount">{user.projects}</span>
                          <span className="sub-text">Complete</span>
                        </div>
                      </Col>
                      <Col size="4">
                        <div className="profile-stats">
                          <span className="amount">{user.performed}</span>
                          <span className="sub-text">Progress</span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="card-inner">
                    <h6 className="overline-title-alt mb-2">Additional</h6>
                    <Row className="g-3">
                      <Col size="6">
                        <span className="sub-text">User ID:</span>
                        <span>UD003054</span>
                      </Col>
                      <Col size="6">
                        <span className="sub-text">Last Login:</span>
                        <span>{user.lastLogin} 01:02 PM</span>
                      </Col>
                      <Col size="6">
                        <span className="sub-text">KYC Status:</span>
                        <span
                          className={`lead-text text-${
                            user.kycStatus === "success"
                              ? "success"
                              : user.kycStatus === "pending"
                              ? "info"
                              : user.kycStatus === "warning"
                              ? "warning"
                              : "secondary"
                          }`}
                        >
                          {user.kycStatus?.toUpperCase()}
                        </span>
                      </Col>
                      <Col size="6">
                        <span className="sub-text">Register At:</span>
                        <span>Nov 24, 2019</span>
                      </Col>
                    </Row>
                  </div>
                  <div className="card-inner">
                    <OverlineTitle tag="h6" className="mb-3">
                      Groups
                    </OverlineTitle>
                    <ul className="g-1">
                      <li className="btn-group">
                        <Button
                          color="light"
                          size="xs"
                          className="btn-dim"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                        >
                          investor
                        </Button>
                        <Button
                          color="light"
                          size="xs"
                          className="btn-icon btn-dim"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                        >
                          <Icon className="ni-cross"></Icon>
                        </Button>
                      </li>
                      <li className="btn-group">
                        <Button
                          color="light"
                          size="xs"
                          className="btn-dim"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                        >
                          support
                        </Button>
                        <Button
                          color="light"
                          size="xs"
                          className="btn-icon btn-dim"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                        >
                          <Icon className="ni-cross"></Icon>
                        </Button>
                      </li>
                      <li className="btn-group">
                        <Button
                          color="light"
                          size="xs"
                          className="btn-dim"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                        >
                          another tag
                        </Button>
                        <Button
                          color="light"
                          size="xs"
                          className="btn-icon btn-dim"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                        >
                          <Icon className="ni-cross"></Icon>
                        </Button>
                      </li>
                    </ul>
                  </div>
                </Sidebar>
                {sideBar && <div className="toggle-overlay" onClick={() => toggle()}></div>}
              </div>
            </Card>
          </Block> */}
          <Block size="lg">
            <PreviewCard>
              <Nav tabs className="nav-tabs nav">
                <NavItem className="p-2 fs-6 fw-medium">
                  <NavLink
                    tag="a"
                    href="#tab"
                    className={classnames({ active: activeIconTab === "1" })}
                    onClick={(ev) => {
                      ev.preventDefault();
                      toggleIconTab("1");
                    }}
                  >
                    <Icon name="user" className="fs-5" /> <span>Personal Information</span>
                  </NavLink>
                </NavItem>
                <NavItem className="p-2 fs-6 fw-medium">
                  <NavLink
                    tag="a"
                    href="#tab"
                    className={classnames({ active: activeIconTab === "2" })}
                    onClick={(ev) => {
                      ev.preventDefault();
                      toggleIconTab("2");
                    }}
                  >
                    <Icon name="calendar-check" className="fs-5" /> <span>Events</span>
                  </NavLink>
                </NavItem>
                <NavItem className="p-2 fs-6 fw-medium">
                  <NavLink
                    tag="a"
                    href="#tab"
                    className={classnames({ active: activeIconTab === "3" })}
                    onClick={(ev) => {
                      ev.preventDefault();
                      toggleIconTab("3");
                    }}
                  >
                    <Icon name="coin-alt" className="fs-5" /> <span>Payments</span>
                  </NavLink>
                </NavItem>
                <NavItem className="p-2 fs-6 fw-medium">
                  <NavLink
                    tag="a"
                    href="#tab"
                    className={classnames({ active: activeIconTab === "4" })}
                    onClick={(ev) => {
                      ev.preventDefault();
                      toggleIconTab("4");
                    }}
                  >
                    <Icon name="grid-fill" className="fs-5" /> <span>Other</span>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeIconTab}>
                <TabPane tabId="1">
                  <div className="card-inner">
                    <Block>
                      <BlockHead>
                        <BlockTitle tag="h5">Personal Information</BlockTitle>
                      </BlockHead>
                      <div className="profile-ud-list">
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label"> User Name</span>
                            <span className="profile-ud-value">John</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Company Name</span>
                            <span className="profile-ud-value">{user.name}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Email Address</span>
                            <span className="profile-ud-value">{user.email}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Mobile Number</span>
                            <span className="profile-ud-value">{user.phone}</span>
                          </div>
                        </div>
                      </div>
                    </Block>
                    <Block>
                      <BlockHead className="nk-block-head-line">
                        <BlockTitle tag="h6" className="overline-title text-base">
                          Additional Information
                        </BlockTitle>
                      </BlockHead>
                      <div className="profile-ud-list">
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Register Date</span>
                            <span className="profile-ud-value">08-16-2018 </span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Address</span>
                            <span className="profile-ud-value">Califorinia, New-York</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Website</span>
                            <span className="profile-ud-value">
                              <a href="https://ipartydjcrm.com/">testInformation.com</a>
                            </span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Nationality</span>
                            <span className="profile-ud-value">United States</span>
                          </div>
                        </div>
                      </div>
                    </Block>
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <div className="card-inner">
                    <Block>
                      <BlockHead>
                        <BlockTitle tag="h5">Event Information</BlockTitle>
                      </BlockHead>
                      <div className="profile-ud-list">
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Total Events</span>
                            <span className="profile-ud-value">100</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Completed Events</span>
                            <span className="profile-ud-value">70</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Pending Events</span>
                            <span className="profile-ud-value">20</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Incompleted Events</span>
                            <span className="profile-ud-value">10</span>
                          </div>
                        </div>
                      </div>
                    </Block>
                    <Block>
                      <BlockHead className="nk-block-head-line">
                        <BlockTitle tag="h6" className="overline-title text-base">
                          Additional Information
                        </BlockTitle>
                      </BlockHead>
                      <div className="profile-ud-list">
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Most Successfull Events</span>
                            <span className="profile-ud-value">Wedding, Birthday, Engagement</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Event Type Count</span>
                            <span className="profile-ud-value">12</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Upcoming Event</span>
                            <span className="profile-ud-value">4</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Average Monthly Events</span>
                            <span className="profile-ud-value">8</span>
                          </div>
                        </div>
                      </div>
                    </Block>
                  </div>
                </TabPane>
                <TabPane tabId="3">
                  <div className="card-inner">
                    <Block>
                      <BlockHead>
                        <BlockTitle tag="h5">Payment Information</BlockTitle>
                      </BlockHead>
                      <div className="profile-ud-list">
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Total Revenue</span>
                            <span className="profile-ud-value">$ 10,000</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label"> Collected Revenue</span>
                            <span className="profile-ud-value">$ 8,000</span>
                          </div>
                        </div>
                        {/* <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Calculated Profit</span>
                            <span className="profile-ud-value">$ 6,000</span>
                          </div>
                        </div> */}
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Upcoming Events Revenue</span>
                            <span className="profile-ud-value">$ 2,000</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Pending Amount</span>
                            <span className="profile-ud-value">$ 2,000</span>
                          </div>
                        </div>
                      </div>
                    </Block>
                    <Block>
                      <BlockHead className="nk-block-head-line">
                        <BlockTitle tag="h6" className="overline-title text-base">
                          Additional Information
                        </BlockTitle>
                      </BlockHead>
                      <div className="profile-ud-list">
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Preferable Payment Types</span>
                            <span className="profile-ud-value">PayPal, Wire Transfer, Card Payment</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Most Used Payment Types</span>
                            <span className="profile-ud-value">PayPal</span>
                          </div>
                        </div>
                      </div>
                    </Block>
                  </div>
                </TabPane>
                <TabPane tabId="4">
                  <div className="card-inner">
                    <Block>
                      <BlockHead>
                        <BlockTitle tag="h5">Other Information</BlockTitle>
                      </BlockHead>
                      <div className="profile-ud-list">
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Total Client</span>
                            <span className="profile-ud-value">20</span>
                          </div>
                        </div>
                      </div>
                      <div className="profile-ud-list">
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Total Employee</span>
                            <span className="profile-ud-value">15</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Calculated Employee Wage</span>
                            <span className="profile-ud-value">$ 1,000</span>
                          </div>
                        </div>
                      </div>
                      <div className="profile-ud-list">
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Total Venues</span>
                            <span className="profile-ud-value">20</span>
                          </div>
                        </div>
                      </div>
                      <div className="profile-ud-list">
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Total Venders</span>
                            <span className="profile-ud-value">15</span>
                          </div>
                        </div>
                      </div>
                      <div className="profile-ud-list">
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Total Packages</span>
                            <span className="profile-ud-value">8</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Highest Package</span>
                            <span className="profile-ud-value">Wedding Pacakge - $ 4,000</span>
                          </div>
                        </div>
                      </div>
                    </Block>
                    {/* <Block>
                      <BlockHead className="nk-block-head-line">
                        <BlockTitle tag="h6" className="overline-title text-base">
                          Additional Information
                        </BlockTitle>
                      </BlockHead>
                      <div className="profile-ud-list">
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Preferable Payment Types</span>
                            <span className="profile-ud-value">PayPal, Wire Transfer, Card Payment</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Most Used Payment Types</span>
                            <span className="profile-ud-value">PayPal</span>
                          </div>
                        </div>
                      </div>
                    </Block> */}
                  </div>
                </TabPane>
              </TabContent>
            </PreviewCard>
          </Block>
        </Content>
      )}
    </React.Fragment>
  );
};
export default ManageProfiles;
