import React, { useEffect, useState } from "react";
import { CompanyGraph } from "./companyGraph";
import { getCookie } from "../../../utils/Utils";

const CompanyAnylatics = () => {
  const [filterData, setFilterData] = useState(null);
  const [checkFilter, setCheckFilter] = useState("monthly");
  const [registeredCompanies, setRegisteredCompanies] = useState(0);

  const companiesFilter = async (filterValue) => {
    try {
      setCheckFilter(filterValue);
      const formData = new FormData();
      formData.append("type", filterValue);
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/admin/get-company-chart-list`, {
        method: "POST",
        body: formData,
        headers: {
          authToken: getCookie("authToken"),
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const filterDataResponse = await response.json();
      if (filterDataResponse.status === true) {
        setFilterData(filterDataResponse?.data);
      } else {
        console.log("Error in response data:", filterDataResponse);
      }
    } catch (error) {
      console.log("Error fetching client list:", error);
    }
  };

  useEffect(() => {
    const countfilterdata = filterData?.datasets?.[0]?.data;
    if (countfilterdata) {
      let companyCount = 0;

      for (let i = 0; i < countfilterdata.length; i++) {
        companyCount += countfilterdata[i];
      }

      setRegisteredCompanies(companyCount);
    } else {
      setRegisteredCompanies(0); // or any default value if filterData.datasets[0].data is undefined
    }
  }, [filterData]); // Dependency array to watch for changes in filterData

  useEffect(() => {
    companiesFilter(checkFilter);
  }, []);

  return (
    <React.Fragment>
      <div className="card-title-group align-start gx-3 mb-3">
        <div className="card-title">
          <h6 className="title fs-4 fw-medium">
            {checkFilter === "monthly" ? "Monthly " : checkFilter === "3 months" ? "last 3 months" : "yearly"} Companies
            Registration
          </h6>
          <p>
            Companies Registered in{" "}
            {checkFilter === "monthly" ? " last month" : checkFilter === "3 months" ? "last 3 months " : "last year"}
          </p>
        </div>
        <div className="card-tools">
          <div className="card-tools shrink-0 w-150px d-sm-block">
            <ul className="nav nav-switch-s2 nav-tabs bg-white justify-center row">
              <li className="nav-item col-12 col-sm-4 justify-end">
                <a
                  href="#navitem"
                  className={checkFilter === "monthly" ? "nav-link text-primary bg-lighter" : "nav-link"}
                  onClick={(e) => {
                    e.preventDefault();
                    companiesFilter("monthly");
                  }}
                >
                  1 M
                </a>
              </li>
              <li className="nav-item col-12 col-sm-4 justify-end">
                <a
                  href="#navitem"
                  className={checkFilter === "3 months" ? "nav-link text-primary bg-lighter" : "nav-link"}
                  onClick={(e) => {
                    e.preventDefault();
                    companiesFilter("3 months");
                  }}
                >
                  3 M
                </a>
              </li>
              <li className="nav-item col-12 col-sm-4 justify-end">
                <a
                  href="#navitem"
                  className={checkFilter === "yearly" ? "nav-link text-primary bg-lighter" : "nav-link"}
                  onClick={(e) => {
                    e.preventDefault();
                    companiesFilter("yearly");
                  }}
                >
                  1 Y
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="nk-sale-data-group align-center justify-between gy-3 gx-5 row">
        <div className="nk-sale-data col-8">
          <div className="amount sm row">
            <span className="col-6">Total Count of Companies:</span>
            <span className="col-6 px-1">
              {registeredCompanies}
              <small> Subscribers</small>
            </span>
          </div>
        </div>
      </div>
      <div className="nk-sales-ck large pt-4 h-200px">
        <CompanyGraph filterData={filterData} />
      </div>
    </React.Fragment>
  );
};
export default CompanyAnylatics;
