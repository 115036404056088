import React, { useContext, useEffect, useState } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { DropdownToggle, DropdownMenu, Card, UncontrolledDropdown, DropdownItem } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Row,
  Col,
  PreviewAltCard,
} from "../../components/Component";
import MonthlyGraph from "./Monthly_Sales/MonthlyGraph";
import TotalEvent from "./TotalEvent";
import FinancialOverview from "./FinancialOverview";
import PopularCompanies from "./PopularCompanies";
import TopEventComp from "./TopEventComp";
import ClientAnylatics from "./ClientRegistration/ClientAnylatics";
import CompanyAnylatics from "./CompanyRegistration/CompanyAnylatics";

const HomePage = () => {
  return (
    <React.Fragment>
      <Head title="Default Dashboard" />
      <Content>
        <BlockHead size="sm">
          <div className="nk-block-between">
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                Events Overview
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>Welcome to Admin Dashboard</p>
              </BlockDes>
            </BlockHeadContent>
          </div>
        </BlockHead>
        <Block>
          <Row className="g-gs justify-around mb-3">
            <Col md={6}>
              <PreviewAltCard className="card-bordered h-100">
                <TotalEvent />
              </PreviewAltCard>
            </Col>
            <Col md={6}>
              <PreviewAltCard className="card-bordered h-100">
                <FinancialOverview />
              </PreviewAltCard>
            </Col>
          </Row>

          <Row className="g-gs">
            <Col md={6}>
              <PreviewAltCard className="card-bordered h-100">
                <PopularCompanies />
              </PreviewAltCard>
            </Col>
            <Col md={6}>
              <PreviewAltCard className="card-bordered h-100">
                <TopEventComp />
              </PreviewAltCard>
            </Col>
          </Row>

          <Row className={"mt-3"}>
            <Col>
              <Row className="g-gs">
                <Col lg="6">
                  <PreviewAltCard className="card-bordered h-100">
                    <MonthlyGraph />
                  </PreviewAltCard>
                </Col>
                <Col lg="6">
                  <PreviewAltCard className="card-bordered h-100">
                    <ClientAnylatics />
                  </PreviewAltCard>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className={"mt-3"}>
            <Col>
              <Row className="g-gs">
                <Col lg="6">
                  <PreviewAltCard className="card-bordered h-100">
                    <CompanyAnylatics />
                  </PreviewAltCard>
                </Col>
              </Row>
            </Col>
          </Row>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default HomePage;
