import React, { useEffect, useState } from "react";
import { Badge, Button, CardTitle, Col, Modal, ModalBody, Row } from "reactstrap";
import { BarChart } from "../../components/partials/charts/sales/Charts";
import { getCookie, parseDateString, transformData } from "../../utils/Utils";
import classNames from "classnames";
import { useNavigate } from "react-router";
import { useQuery, useQueryClient } from "react-query";
import { getCompanyList, getCountries, getEventType, getEventsList, getStates } from "../../http/get/getApi";
import { Icon } from "../../components/Component";
import PuffLoader from "react-spinners/PuffLoader";
import ReactSelect from "react-select";

let token = process.env.REACT_APP_AUTH_TOKEN;

const TotalEvent = () => {
  const [loading, setloading] = useState(false);
  const [countryName, setCountryName] = useState(null);
  const [selectedCountry, setSelectedcountry] = useState(null);
  const [eventTypeVal, setEventTypeVal] = useState(null);
  const [companyVal, setCompanyVal] = useState(null);
  const [StateVal, setStateVal] = useState(null);
  const [isClearable, setIsClearable] = useState(true);

  const queryClient = useQueryClient();

  const [view, setView] = useState({
    edit: false,
    add: false,
    details: false,
  });

  const FilterApiCall = () => {
    toggleModal();
  };

  //APi to fetch total events
  // const {
  //   data: totalEvents,
  //   isLoading: totalEventsLoading,
  //   isError: totalEventsError,
  // } = useQuery({
  //   queryKey: ["get-events-list"],
  //   queryFn: () => getTotalEventsList(),
  // });

  // let totalevent = 0;
  // for (let i = 0; i < eventdata?.length; i++) {
  //   totalevent++;
  // }

  // var successfulevent = 0;
  // for (let i = 0; i < eventdata?.length; i++) {
  //   if (
  //     eventdata?.[i]?.start_date_time &&
  //     eventdata?.[i]?.package_id &&
  //     eventdata?.[i]?.event_financials?.status === "4" &&
  //     eventdata?.[i]?.start_date_time < parseDateString(new Date())
  //   ) {
  //     successfulevent++;
  //   }
  // }

  // var incomplete_Events = 0;
  // for (let i = 0; i < eventdata?.length; i++) {
  //   if (
  //     eventdata?.[i]?.event_financials?.status !== "4" &&
  //     eventdata?.[i]?.start_date_time < parseDateString(new Date())
  //   ) {
  //     incomplete_Events++;
  //   }
  // }
  // var pending_event = 0;
  // for (let i = 0; i < eventdata?.length; i++) {
  //   if (eventdata?.[i]?.start_date_time > parseDateString(new Date())) {
  //     pending_event++;
  //   }
  // }
  // const finalPorogressVal = ((successfulevent / totalevent) * 100).toFixed(2);
  // const BtnHover = {
  //   hover: {
  //     y: 1,
  //   },
  // };

  //fetching companies list
  const {
    data: companies,
    isLoading: companiesLoading,
    isError: companiesError,
  } = useQuery({
    queryKey: ["get-companies"],
    queryFn: () => getCompanyList(),
  });

  const companyOptions = transformData({
    dataArray: companies?.data?.list || [],
    label: "company_name",
    value: "id",
    isLoading: companiesLoading,
  });

  //fetching eventTypes data
  const {
    data: eventTypes,
    isLoading: eventTypesLoading,
    isError: eventTypesError,
  } = useQuery({
    queryKey: ["get-eventTypes"],
    queryFn: () => getEventType({ type: "1" }),
  });

  const eventTypesOptions = transformData({
    dataArray: eventTypes?.data?.event_type || [],
    label: "name",
    value: "id",
    isLoading: eventTypesLoading,
  });

  //fetching countries list
  const {
    data: country,
    isLoading: countryLoading,
    isError: countryError,
  } = useQuery({
    queryKey: ["get-countries"],
    queryFn: () => getCountries(),
    onSuccess: () => {
      queryClient.invalidateQueries(["get-states-by-country"]);
    },
  });

  const countriesOptions = transformData({
    dataArray: country?.data?.countries || [],
    label: "name",
    value: "id",
    isLoading: countryLoading,
  });

  //fetching states list
  const {
    data: states,
    isLoading: statesLoading,
    isError: statesError,
  } = useQuery({
    queryKey: ["get-states-by-country", selectedCountry],
    queryFn: () => getStates({ id: selectedCountry }),
  });

  //optiopns for states dropdown
  const statesOptions = transformData({
    dataArray: states?.data?.states || [],
    label: "name",
    value: "id",
    isLoading: statesLoading,
  });

  function handleEventType(e) {
    setEventTypeVal(e);
  }
  function handleCompany(e) {
    setCompanyVal(e);
  }
  function handleCountry(e) {
    setCountryName(e);
    setSelectedcountry(e.value);
  }
  function handleState(e) {
    setStateVal(e);
  }

  const toggleModal = () => {
    setView({ ...view, edit: !view.edit });
    setEventTypeVal(null);
    setCompanyVal(null);
    setCountryName(null);
    setSelectedcountry(null);
    setStateVal(null);
  };

  return (
    <React.Fragment>
      <div className="card-title-group align-start mb-2 align-center">
        <CardTitle className="fs-4 fw-medium">
          {/* <Badge className=" badge-dot badge-lg fs-1" color="gray">
            Events
          </Badge> */}
          Events
        </CardTitle>
        <div className="card-tools">
          <Button className="btn-dim bg-primary text-white px-4 p-0" onClick={toggleModal}>
            <span className="sub-text text-white">Filter</span>
          </Button>
        </div>
      </div>
      <div className="row text-center justify-around">
        <div className="nk-sale-data col-auto">
          <span className="fw-bold">Total Events</span>
          <span className="amount">200</span>
          <span className="sub-title">
            {/* {totalevent === 0 ? (
              <span className="change down text-danger">
                <Icon name="arrow-long-up" />0 since last month
              </span>
            ) : (
              <span className="change up text-success">
                <Icon name="arrow-long-up" />
                {totalevent} since last month
              </span>
            )} */}
          </span>
        </div>
        <div className="nk-sale-data col-auto">
          <span className="fw-bold">Upcoming Events</span>
          <span className="amount">50</span>
          <span className="sub-title">
            {/* {successfulevent === 0 ? (
              <span className="change down text-danger">
                <Icon name="arrow-long-down" />0 since last month
              </span>
            ) : (
              <span className="change up text-success">
                <Icon name="arrow-long-up" />
                {successfulevent} since last month
              </span>
            )} */}
          </span>
        </div>
        <div className="nk-sale-data col-auto">
          <span className="fw-bold">Past Events</span>
          <span className="amount">150</span>
          <span className="sub-title">
            <span className="change down text-danger">
              {/* <Icon name="arrow-long-down" /> */}
              {/* {incomplete_Events} since last month */}
            </span>
          </span>
        </div>
        <div className="nk-sale-data col-auto">
          <span className="fw-bold">Successfull Events</span>
          <span className="amount">120</span>
          <span className="sub-title">
            <span className="change down text-danger">
              {/* <Icon name="arrow-long-down" />
              {pending_event} since last month */}
            </span>
          </span>
        </div>
        <div className="nk-sale-data col-auto">
          <span className="fw-bold">Cancelled Events</span>
          <span className="amount">30</span>
          <span className="sub-title">
            <span className="change down text-danger">
              {/* <Icon name="arrow-long-down" /> */}
              {/* {pending_event} since last month */}
            </span>
          </span>
        </div>
      </div>

      <Modal isOpen={view.edit} className="modal-dialog-centered" size="lg" toggle={toggleModal}>
        <ModalBody>
          <a href="#cancel" className="close" onClick={toggleModal}>
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">Filter</h5>
            <div className="mt-4">
              <form noValidate>
                <Row className="g-3 mb-3">
                  <Col md={6}>
                    <div className="form-group">
                      <label className="form-label" htmlFor="product-title">
                        Company
                      </label>
                      <ReactSelect options={companyOptions} value={companyVal} onChange={(e) => handleCompany(e)} />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <label className="form-label" htmlFor="product-title">
                        Event Types
                      </label>
                      <ReactSelect
                        options={eventTypesOptions}
                        value={eventTypeVal}
                        isClearable={isClearable}
                        onChange={(e) => handleEventType(e)}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="g-3">
                  <Col md={6}>
                    <div className="form-group">
                      <label className="form-label" htmlFor="product-title">
                        Country
                      </label>
                      <ReactSelect options={countriesOptions} value={countryName} onChange={(e) => handleCountry(e)} />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <label className="form-label" htmlFor="product-title">
                        State
                      </label>
                      <ReactSelect options={statesOptions} value={StateVal} onChange={(e) => handleState(e)} />
                    </div>
                  </Col>
                </Row>
                <Row className="g-3 mt-3">
                  <Col md={6}>
                    <Button color="primary" type="submit" disabled={loading} onClick={FilterApiCall}>
                      <span className="px-0 mx-0">Filter</span>
                      {loading ? <PuffLoader speedMultiplier="2" size="30" color={"#39ff00"} /> : ""}
                    </Button>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
export default TotalEvent;
