import React, { useEffect, useState } from "react";
import { Icon, Button, Col, Row } from "../../../components/Component";
import { Modal, ModalBody } from "reactstrap";
import { useForm } from "react-hook-form";
import Select, { components } from "react-select";
import PuffLoader from "react-spinners/PuffLoader";

const Option = (props) => (
  <components.Option {...props} className="country-option">
    <img src={props.data.icon} alt="logo" className="country-logo" style={{ width: "20px", marginRight: "10px" }} />
    {props.data.label}
  </components.Option>
);

const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    <img src={props.data.icon} alt="s-logo" className="selected-logo" style={{ width: "20px", marginRight: "10px" }} />
    {children}
  </components.SingleValue>
);

const CompanyListModels = ({ view, modalType, formData, setFormData, onFormCancel, onSubmit, loading }) => {
  const [passState, setPassState] = useState(false);
  const [confirmPassState, setConfirmPassState] = useState(false);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const {
    reset,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const password = watch("password");

  useEffect(() => {
    reset(formData);
  }, [formData, reset]);

  // const blockInvalidChars = (e) => {
  //   const invalidChars = ["-", "e", "E", ".", "+"];
  //   if (invalidChars.includes(e.key)) {
  //     e.preventDefault();
  //   }
  // };

  // const limitInputLength = (e, maxLength) => {
  //   if (e.target.value.length >= maxLength) {
  //     e.preventDefault();
  //   }
  // };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}api/v1/public/countries`)
      .then((response) => response.json())
      .then((data) => {
        const countriesData = data.data.countries
        .filter((country) => country.id === 101 || country.id === 231)
        .map((country) => ({
          value: country.shortname,
          label: "+" + country.phonecode,
          id: country.id,
          icon: `https://flagsapi.com/${country.shortname}/shiny/64.png`,
        }));
        setCountries(countriesData);
        setSelectedCountry(countriesData[0]);
        setFormData({ ...formData, country: countriesData[0].id });
      })
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);

  // This will send the +91 to backend
  // const handleCountryChange = (value) => {
  //   setSelectedCountry(value);
  //   setFormData({ ...formData, country: value.label });
  // };

  // This will send the 91 to backend
  const handleCountryChange = (value) => {
    const numericCountryCode = value.label.replace("+", ""); // Remove the "+" symbol
    setSelectedCountry(value);
    setFormData({ ...formData, country: numericCountryCode }); 
  };

  const validatePhoneNumber = (value) => {
    const regex = /^\d+$/;
    return regex.test(value) || "Number must contain only digits";
  };

  return (
    <Modal isOpen={view} toggle={onFormCancel} className="modal-dialog-centered" size="lg">
      <ModalBody>
        <a
          href="#cancel"
          className="close"
          onClick={(ev) => {
            ev.preventDefault();
            onFormCancel();
          }}
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">{modalType === "add" ? "Add Information" : "Update Information"}</h5>
          <div className="mt-4">
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <Row className="g-3">
                <Col size="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="frm_username">
                      User Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter User Name"
                      {...register("frm_username", {
                        required: "This field is required",

                        minLength: {
                          value: 3,
                          message: "Please enter at least 3 characters",
                        },
                      })}
                      maxLength={50}
                      value={formData.frm_username}
                      onChange={(e) => setFormData({ ...formData, frm_username: e.target.value })}
                    />
                    {errors.frm_username && <span className="invalid">{errors.frm_username.message}</span>}
                  </div>
                </Col>
                <Col size="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="company_name">
                      Company Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Company Name"
                      {...register("company_name", {
                        required: "This field is required",
                      })}
                      maxLength={50}
                      value={formData.company_name}
                      onChange={(e) => setFormData({ ...formData, company_name: e.target.value })}
                    />
                    {errors.company_name && <span className="invalid">{errors.company_name.message}</span>}
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="country">
                      Country Code
                    </label>
                    {/* <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Country Code"
                      {...register("country", {
                        // required: "This field is required",
                        maxLength: {
                          value: 4,
                          message: "Phone number cannot exceed 4 digits",
                        },
                        validate: validatePhoneNumber,
                      })}
                      value={formData.country}
                      onChange={(e) => setFormData({ ...formData, country: parseInt(e.target.value) || "" })}
                    /> */}
                    <Select
                      className="w-100"
                      value={selectedCountry}
                      // value={formData.country}
                      options={countries}
                      onChange={handleCountryChange}
                      // onChange={(e) => setFormData({ ...formData, country: parseInt(e.target.value) || "" })}
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          color: state.isSelected ? "white" : "black",
                          backgroundColor: state.isSelected ? "#007bff" : "white",
                          display: "flex",
                          alignItems: "center",
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          display: "flex",
                          alignItems: "center",
                        }),
                      }}
                      components={{ Option, SingleValue }}
                    />
                     {errors.country && <p className="invalid">{errors.country.message}</p>}
                  </div>
                </Col>

                <Col md="6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="phone_number">
                      Contact Number
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                      placeholder="Enter Contact Number"
                      {...register("phone_number", {
                        maxLength: {
                          value: 16,
                          message: "Phone number cannot exceed 16 digits",
                        },
                        validate: validatePhoneNumber,
                        // required: "This field is required",
                      })}
                      value={formData.phone_number}
                      onChange={(e) => setFormData({ ...formData, phone_number: parseInt(e.target.value) || "" })}
                    />
                    {errors.phone_number && <p className="invalid">{errors.phone_number.message}</p>}
                  </div>
                </Col>

                <Col md="6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="email">
                      Email
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Email"
                      {...register("email", {
                        required: "This is required",
                        pattern: {
                          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                          message: "Enter a valid email address",
                        },
                        maxLength: {
                          value: 255,
                          message: "Email must not exceed 255 characters",
                        },
                      })}
                      value={formData.email}
                      onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    />
                    {errors.email && <span className="invalid">{errors.email.message}</span>}
                  </div>
                </Col>
                <Col md="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="address_1">
                      Address
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Company Address"
                      {...register("address_1", { required: "This is required" })}
                      maxLength={255}
                      value={formData.address_1}
                      onChange={(e) => setFormData({ ...formData, address_1: e.target.value })}
                    />
                    {errors.address_1 && <span className="invalid">{errors.address_1.message}</span>}
                  </div>
                </Col>
                {modalType === "add" && (
                  <>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="password">
                          Password
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type={passState ? "text" : "password"}
                            className="form-control"
                            id="password"
                            placeholder="Enter Password"
                            {...register("password", {
                              required: "Password is required",
                              pattern: {
                                value: /^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                                message: "Enter strong password (e.g., Abc@1234)",
                              },
                              maxLength: {
                                value: 12,
                                message: "Password must not exceed 12 characters",
                              },
                            })}
                            value={formData.password}
                            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                          />
                          <a
                            href="#password"
                            onClick={(ev) => {
                              ev.preventDefault();
                              setPassState(!passState);
                            }}
                            className={`form-icon lg form-icon-right passcode-switch h-100 ${
                              passState ? "is-hidden" : "is-shown"
                            }`}
                          >
                            <Icon name="eye" className="passcode-icon icon-show"></Icon>
                            <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                          </a>
                        </div>
                        {/* {errors.password && <span className="invalid">{errors.password.message}</span>} */}
                        {errors.password && (
                          <span className="invalid" style={{ color: "#e85347", fontSize: "11px", fontStyle: "italic" }}>
                            {errors.password.message}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="password">
                          Confirm Password
                        </label>
                        <div className="form-control-wrap">
                          <a
                            href="#password"
                            onClick={(ev) => {
                              ev.preventDefault();
                              setConfirmPassState(!confirmPassState);
                            }}
                            className={`form-icon lg form-icon-right passcode-switch h-100 ${
                              confirmPassState ? "is-hidden" : "is-shown"
                            }`}
                          >
                            <Icon name="eye" className="passcode-icon icon-show"></Icon>
                            <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                          </a>
                          <input
                            type={confirmPassState ? "text" : "password"}
                            className="form-control"
                            id="confirmPassword"
                            placeholder="Enter Confirm Password"
                            {...register("confirmPassword", {
                              required: "Confirm password is required",
                              validate: (value) => value === password || "Passwords do not match",
                              maxLength: {
                                value: 12,
                                message: "Password must not exceed 12 characters",
                              },
                            })}
                            value={formData.confirmPassword}
                            onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
                          />
                        </div>
                        {/* {errors.confirmPassword && <span className="invalid">{errors.confirmPassword.message}</span>} */}
                        {errors.confirmPassword && (
                          <span className="invalid" style={{ color: "#e85347", fontSize: "11px", fontStyle: "italic" }}>
                            {errors.confirmPassword.message}
                          </span>
                        )}
                      </div>
                    </Col>
                  </>
                )}
                <Col size="12" className="d-flex justify-center">
                  <Button color="primary" type="submit" disabled={loading}>
                    <Icon className="plus"></Icon>
                    <span className="mx-auto px-0">
                      {modalType === "add" ? "Add Information" : "Update Information"}
                    </span>
                    {loading && <PuffLoader speedMultiplier="2" size="30px" color={"#39ff00"} />}
                  </Button>
                </Col>
              </Row>
            </form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CompanyListModels;
