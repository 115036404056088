import { useNavigate } from "react-router";
import { Button } from "reactstrap";
import { Icon } from "../../components/Component";
import { motion } from "framer-motion";

const NavigationHeader = ({ heading, buttonInnerText, route, back, removeIcon = false, total, limit }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(route);
    localStorage.removeItem("EventformData");
  };

  const priveHover = {
    hover: {
      x: -3,
    },
  };

  return (
    <>
      <div className="container-fluid">
        <div className=" nk-block-head d-flex justify-content-between mx-1 mt-4">
          <div className="d-flex">
            <h3>{heading}</h3>
            {/* <h5 className="align-self-center mx-2">
              ({total}/{limit})
            </h5> */}
          </div>
          {buttonInnerText && (
            <>
              {removeIcon ? (
                <Button color="primary" onClick={handleClick}>
                  <span>{buttonInnerText}</span>
                </Button>
              ) : (
                <motion.div whileHover={"hover"}>
                  <Button color="primary" onClick={() => navigate(route)}>
                    {back ? (
                      <motion.span variants={priveHover}>
                        <Icon className="icon ni ni-arrow-long-left"></Icon>
                      </motion.span>
                    ) : (
                      <Icon className="ni-plus"></Icon>
                    )}
                    <span>{buttonInnerText}</span>
                  </Button>
                </motion.div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default NavigationHeader;
