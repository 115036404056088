import React, { useEffect, useState } from "react";
import { getCookie } from "../../../utils/Utils";
import { Link } from "react-router-dom";
import debounce from "lodash.debounce";
const LogoFetch = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const fetchData = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("field_key", "sidebar_logo");
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/get-site-settings`, {
        method: "POST",
        body: formData,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      setData(responseData?.data?.site_settings?.[0]?.field_value);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const debounceFetchLogo = debounce(fetchData, 1000);
    debounceFetchLogo();
    return () => {
      debounceFetchLogo.cancel();
    };
  }, []);
  return (
    <>
      {data && (
        <div className="nk-apps-brand">
          <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link px-3 py-4">
            <img className="logo-light " src={`${process.env.REACT_APP_API_URL}` + data} alt="logo" />
            <img className="logo-dark logo-img" src={`${process.env.REACT_APP_API_URL}` + data} alt="logo-dark" />
          </Link>
        </div>
      )}
    </>
  );
};
export default LogoFetch;
