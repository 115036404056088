let menu = [
  {
    heading: "Dashboards",
  },
  {
    icon: "dashboard",
    text: "Overview",
    link: "/",
    dashboard: true,
  },
];
menu = [
  {
    icon: "dashboard",
    text: "Overview",
    link: "/",
    dashboard: true,
  },
  {
    icon: "icon ni ni-list-thumb-alt",
    text: "Event list",
    link: "/event-list",
  },
  {
    icon: "icon ni ni-list-copy",
    text: "Company List",
    link: "/company-list",
  },
  {
    icon: "icon ni ni-list-thumb-alt",
    text: "Site Setting",
    link: "/site-setting",
  },
  {
    icon: "icon ni ni-list-thumb-alt",
    text: "Subscription Plans",
    link: "/project-card",
  },
];

export default menu;
