
const getCroppedImg = (imageSrc, croppedAreaPixels, targetSize) => {
    const image = new Image();
    image.src = imageSrc;
  
    return new Promise((resolve, reject) => {
      image.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
  
        // Set canvas size to target dimensions
        canvas.width = targetSize.width;
        canvas.height = targetSize.height;
  
        // Draw and scale the cropped area to fit the target dimensions
        ctx.drawImage(
          image,
          croppedAreaPixels.x,
          croppedAreaPixels.y,
          croppedAreaPixels.width,
          croppedAreaPixels.height,
          0,
          0,
          targetSize.width,
          targetSize.height
        );
  
        // Get base64 image
        const base64Image = canvas.toDataURL("image/jpeg");
        resolve(base64Image); // Return cropped base64 image
      };
  
      image.onerror = (error) => reject(error);
    });
};


export default getCroppedImg;