import { Bar } from "react-chartjs-2";

const valuesForMonth = [
  1500, 1600, 1700, 1800, 1900, 1000, 2100, 2200, 2300, 2400, 2500, 2600, 2700, 2800, 2900, 3000, 1100, 3200, 3300,
  3400, 3500, 3600, 1700, 3800, 3900, 4000, 4100, 2200, 4300, 1500,
];
export const monthRevenue = {
  labels: [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
  ],
  dataUnit: "USD",
  stacked: true,
  datasets: [
    {
      label: "Sales Revenue",
      width: "30",
      barPercentage: 0.7,
      categoryPercentage: 0.7,
      backgroundColor: [
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
      ],
      data: valuesForMonth,
    },
  ],
};

const valuesForYear = [30000, 35000, 32000, 34000, 36000, 33000, 37000, 38000, 39000, 40000, 41000, 42000]; // example year data
export const yearRevenue = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  dataUnit: "USD",
  stacked: true,
  datasets: [
    {
      label: "Sales Revenue",
      width: "30",
      barPercentage: 0.7,
      categoryPercentage: 0.7,
      backgroundColor: [
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
        "rgba(10,120,89,0.2)",
      ],
      data: valuesForYear,
    },
  ],
};

const valuesForQuarter = [8000, 10000, 9000, 11000, 9500, 10500, 10000, 11500, 9500, 12000, 11000];
export const quarterRevenue = {
  labels: [
    "Week 1",
    "Week 2",
    "Week 3",
    "Week 4",
    "Week 5",
    "Week 6",
    "Week 7",
    "Week 8",
    "Week 9",
    "Week 10",
    "Week 11",
    "Week 12",
  ],
  dataUnit: "USD",
  stacked: true,
  datasets: [
    {
      label: "Sales Revenue",
      width: "30",
      barPercentage: 0.7,
      categoryPercentage: 0.7,
      backgroundColor: Array(13).fill("rgba(10,120,89,0.2)"),
      data: valuesForQuarter,
    },
  ],
};

export const EventPriceBar = ({ auOverview, setTotalRevenue }) => {
  let Revenue = auOverview === "month-1" ? monthRevenue : auOverview === "month-3" ? quarterRevenue : yearRevenue;

  if (auOverview === "month-1") {
    const total = valuesForMonth.reduce((acc, value) => acc + value, 0);
    setTotalRevenue(total);
  } else if (auOverview === "month-3") {
    const total = valuesForQuarter.reduce((acc, value) => acc + value, 0);
    setTotalRevenue(total);
  } else {
    const total = valuesForYear.reduce((acc, value) => acc + value, 0);
    setTotalRevenue(total);
  }

  return (
    <Bar
      className="sales-bar-chart chartjs-render-monitor"
      data={Revenue}
      options={{
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            displayColors: false,
            backgroundColor: "#eff6ff",
            titleFont: {
              size: "11px",
            },
            titleColor: "#6783b8",
            titleMarginBottom: 4,
            bodyColor: "#9eaecf",
            bodyFont: {
              size: "10px",
            },
            bodySpacing: 3,
            padding: 8,
            footerMarginTop: 0,
          },
        },
        scales: {
          y: {
            display: false,
          },
          x: {
            display: false,
          },
        },
        maintainAspectRatio: false,
      }}
    />
  );
};
