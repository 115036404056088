import React from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import PlanRegistration from "./PlanRegistration";
const Paypal = ({ handleSubmitSubscribePayment, price }) => {
  const initialOptions = {
    "Client-id": "AQZbi5QAbfR6FkoAXQoR8vTqMqtVvXofmVwbWytgl43EvLpKEsyuWg42DaUXgh20brjZDICBz7lR46fV",
    currency: "USD",
    intent: "capture",
    "disable-funding": "card",
  };
  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [{ amount: { currency_code: "USD", value: price } }],
    });
  };
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const payment_txn_id = details?.purchase_units?.[0].payments.captures?.[0]?.id;
      const payment_response = details;
      const amount = details?.purchase_units?.[0].amount.value;
      const status = details.status;
      handleSubmitSubscribePayment(status, payment_txn_id, payment_response, amount);
    });
  };
  return (
    <PayPalScriptProvider options={initialOptions}>
      {" "}
      <PayPalButtons
        createOrder={(data, actions) => createOrder(data, actions)}
        onApprove={(data, actions) => onApprove(data, actions)}
        onError={(err) => console.log(err)}
      />{" "}
    </PayPalScriptProvider>
  );
};
export default Paypal;
