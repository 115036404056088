import React, { useState } from "react";
import { CardTitle } from "reactstrap";
import { Icon, TooltipComponent } from "../../../components/Component";
import { EventPriceBar } from "./EventPriceBar";

const MonthlyGraph = () => {
  const [auOverview, setAuOverview] = useState("month-1");
  const [totalRevenue, setTotalRevenue] = useState(0.0);

  return (
    <React.Fragment>
      <div className="card-title-group align-start mb-2">
        <CardTitle>
          <h6 className="title fs-4 fw-medium">
            {auOverview === "month-1" ? "Monthly " : auOverview === "month-3" ? "Quarterly " : "Yearly "}
            Event Analytics
          </h6>
          <p className="fs-8">Monthly, Quarterly and Yearly Event Analytics</p>
        </CardTitle>
        <div className="card-tools">
          <div className="card-tools shrink-0 w-150px d-sm-block ">
            <ul className="nav nav-switch-s2 nav-tabs bg-white justify-center row">
              <li className="nav-item col-12 col-sm-4 justify-end">
                <a
                  href="#navitem"
                  className={auOverview === "month-1" ? "nav-link text-primary bg-lighter" : "nav-link"}
                  onClick={(e) => {
                    e.preventDefault();
                    setAuOverview("month-1");
                  }}
                >
                  1 M
                </a>
              </li>
              <li className="nav-item col-12 col-sm-4 justify-end">
                <a
                  href="#navitem"
                  className={auOverview === "month-3" ? "nav-link text-primary bg-lighter" : "nav-link"}
                  onClick={(e) => {
                    e.preventDefault();
                    setAuOverview("month-3");
                  }}
                >
                  3 M
                </a>
              </li>
              <li className="nav-item col-12 col-sm-4 justify-end">
                <a
                  href="#navitem"
                  className={auOverview === "year-1" ? "nav-link text-primary bg-lighter" : "nav-link"}
                  onClick={(e) => {
                    e.preventDefault();
                    setAuOverview("year-1");
                  }}
                >
                  1 Y
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="align-end gy-3 gx-5 flex-wrap flex-md-nowrap flex-lg-wrap flex-xxl-nowrap">
        <div className="nk-sale-data-group flex-md-nowrap g-4">
          <div className="nk-sale-data">
            <span className="amount">
              ${totalRevenue === 0 ? "0.00" : totalRevenue}
              {/* <span className="change up text-success">
                <Icon name="arrow-long-up" />
                0.00%
              </span> */}
            </span>
            <span className="sub-title fs-6">
              {auOverview === "month-1"
                ? "This Month Event Revenue"
                : auOverview === "month-3"
                ? "This Quarter Event Revenue"
                : "This Year Event Revenue"}
            </span>
          </div>
        </div>
      </div>
      <div className="align-end gy-3 gx-5 flex-wrap flex-md-nowrap flex-lg-wrap flex-xxl-nowrap">
        <div className="nk-sales-ck sales-revenue">
          <EventPriceBar auOverview={auOverview} setTotalRevenue={setTotalRevenue} />
        </div>
      </div>
    </React.Fragment>
  );
};
export default MonthlyGraph;
