import React from "react";
import classNames from "classnames";
import Toggle from "../sidebar/Toggle";
import User from "./dropdown/user/User";
import Notification from "./dropdown/notification/Notification";
import AppDropdown from "./dropdown/app/App";
import logo from "../../images/favicon.png";

import { useTheme, useThemeUpdate } from "../provider/Theme";

const AppHeader = ({ fixed, className, app }) => {
  const theme = useTheme();
  const themeUpdate = useThemeUpdate();

  const headerClass = classNames({
    "nk-header": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme.header === "white",
    [`is-${theme.header}`]: theme.header !== "white" && theme.header !== "light",
    [`${className}`]: className,
  });

  const textColorClass = classNames({
    "text-primary": theme.header === "dark",
    "text-black": theme.header === "white" || theme.header === "light",
  });

  return (
    <div className={headerClass}>
      <div className="container-fluid">
        <div className="nk-header-wrap">
          <div className="nk-header-app-name">
            <div className="nk-header-app-info">
              <span className="sub-text fs-6 fw-medium text-primary">Admin</span>
              <span className="lead-text fs-5">{app.text}</span>
            </div>
          </div>
          <div className="nk-header-tools">
            <ul className="nk-quick-nav">
              {/* Login As DJ Link with Hover Effect */}
              <li>
                <p>
                  <a
                    href={`${process.env.REACT_APP_PUBLIC_URL}company-list`}
                    className="text-decoration-none"
                    style={{
                      color: "inherit",
                      transition: "color 0.3s ease",
                    }}
                    onMouseEnter={(e) => e.target.style.color = "#26b33e"} // Change to green on hover
                    onMouseLeave={(e) => e.target.style.color = "inherit"} // Revert color on mouse leave
                  >
                    Login As DJ
                  </a>
                </p>
              </li>

              {/* Notification Icon with Hover Effect */}
              <li className="notification-dropdown me-n1">
                <Notification />
              </li>

              {/* Apps Dropdown */}
              <li className="list-apps-dropdown d-lg-none">
                <AppDropdown />
              </li>

              {/* User Icon with Hover Effect */}
              <li className="user-dropdown">
                <User />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppHeader;
