import React, { useEffect, useState } from "react";
import { ClientGraph } from "./clientGraph";
import { getCookie } from "../../../utils/Utils";
import { Link } from "react-router-dom";

const ClientAnalytics = () => {
  const [filterData, setFilterData] = useState(null);
  const [checkFilter, setCheckFilter] = useState("monthly");
  const [registeredClients, setRegisteredClients] = useState(0);

  const clientFilter = async (filterValue) => {
    try {
      setCheckFilter(filterValue);
      const formData = new FormData();
      formData.append("type", filterValue);
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/admin/get-client-list`, {
        method: "POST",
        body: formData,
        headers: {
          authToken: getCookie("authToken"),
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const filterDataResponse = await response.json();
      if (filterDataResponse?.status === true) {
        setFilterData(filterDataResponse?.data);
      } else {
        console.log("Error in response data:", filterDataResponse);
      }
    } catch (error) {
      console.log("Error fetching client list:", error);
    }
  };

  useEffect(() => {
    const countfilterdata = filterData?.datasets?.[0]?.data;
    if (countfilterdata) {
      let clientCount = 0;

      for (let i = 0; i < countfilterdata.length; i++) {
        clientCount += countfilterdata[i];
      }

      setRegisteredClients(clientCount);
    } else {
      setRegisteredClients(0); // or any default value if filterData.datasets[0].data is undefined
    }
  }, [filterData]); // Dependency array to watch for changes in filterData

  useEffect(() => {
    clientFilter(checkFilter);
  }, []);

  return (
    <React.Fragment>
      <div className="card-title-group align-start gx-3 mb-3">
        <div className="card-title">
          <h6 className="title fs-4 fw-medium">
            {checkFilter === "monthly" ? "Monthly" : checkFilter === "3 months" ? "Quarterly" : "Yearly"} Client
            Registration
          </h6>
          <p>
            Clients Registered in{" "}
            {checkFilter === "monthly" ? "last month" : checkFilter === "3 months" ? "last 3 months" : "last year"}
          </p>
        </div>
        <div className="card-tools">
          <div className="card-tools shrink-0 w-150px d-sm-block">
            <ul className="nav nav-switch-s2 nav-tabs bg-white justify-center row">
              <li className="nav-item col-12 col-sm-4 justify-end">
                <Link
                  className={checkFilter === "monthly" ? "nav-link text-primary bg-lighter" : "nav-link"}
                  onClick={(e) => {
                    e.preventDefault();
                    clientFilter("monthly");
                  }}
                >
                  1 M
                </Link>
              </li>
              <li className="nav-item col-12 col-sm-4 justify-end">
                <Link
                  className={checkFilter === "3 months" ? "nav-link text-primary bg-lighter" : "nav-link"}
                  onClick={(e) => {
                    e.preventDefault();
                    clientFilter("3 months");
                  }}
                >
                  3 M
                </Link>
              </li>
              <li className="nav-item col-12 col-sm-4 justify-end">
                <Link
                  className={checkFilter === "yearly" ? "nav-link text-primary bg-lighter" : "nav-link"}
                  onClick={(e) => {
                    e.preventDefault();
                    clientFilter("yearly");
                  }}
                >
                  1 Y
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="nk-sale-data-group align-center justify-between gy-3 gx-5 row">
        <div className="nk-sale-data col-8">
          <div className="amount sm row">
            <span className="col-6">Total Count of Clients:</span>
            <span className="col-6 px-1">
              {registeredClients}
              <small> Subscribers</small>
            </span>
          </div>
        </div>
      </div>
      <div className="nk-sales-ck large pt-4 h-200px">
        <ClientGraph filterData={filterData} checkFilter={checkFilter} />
      </div>
    </React.Fragment>
  );
};

export default ClientAnalytics;
